@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-primary-scheme: (
  100: #c86161,
  500: #cb4242,
  700: #c61919,
  contrast: (
    100: #ffffff,
    500: #ffffff,
    700: #ffffff,
  ),
);
$mat-accent-scheme: (
  100: #348dd7,
  500: #2286d8,
  700: #0a63ab,
  contrast: (
    100: #ffffff,
    500: #ffffff,
    700: #ffffff,
  ),
);
$app-primary: mat.define-palette($mat-primary-scheme);
$app-accent: mat.define-palette($mat-accent-scheme);
$app-warn: mat.define-palette(mat.$orange-palette);
$app-success: mat.define-palette(mat.$green-palette);
$app-error: mat.define-palette(mat.$red-palette);
$app-bg-light: mat.define-palette(mat.$grey-palette, 300);
// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
@font-face {
  font-family: 'Bamboo';
  src: local('Bamboo'),
    url(./assets/fonts/Bamboo-Regular.otf) format('opentype');
}

:root {
  --lowcorrect: #dbffc2; //light green
  --medcorrect: #99e346; //med green
  --highcorrect: #64bd00; //dark green
  --lowwrong: #ffd1d1; //light greyed
  --medwrong: #ff7e89; //med red
  --highwrong: #de2a3a; //dark red
  --unattempted: #7f6d6f; //gray
  --graphite: #303030;
  --graphite-90: #303030c8;
  --ash: #777777;
  --ash-50: #77777750;
  --ash-20: #77777720;
  --light-gray: #bbbfca;
  --soul: #f7f7f7;
  --soul-50: #f7f7f750;
  --transparent: #ffffff00;
  --brick-red: #cb4242;
  --brick-red-20: #cb424220;
  --brick-red-50: #cb424250;
  --dull-blue: #3ba9e4;
  --bottle-blue: #2286d8;
  --bottle-blue-20: #2286d820;
  --bottle-blue-50: #2286d850;
  --olive-green: #179e7e;
  --olive-green-20: #179e7e20;
  --olive-green-50: #179e7e50;
  --grass-green: #24d16c;
  --mask-orange: #ed763e;
  --lemon-yellow: #ffc800;
  --color-primary: #{mat.get-color-from-palette($app-primary)};
  --color-primary-dark: #{mat.get-color-from-palette($app-primary, 700)};
  --color-primary-light: #{mat.get-color-from-palette($app-primary, 100)};
  --color-accent: #{mat.get-color-from-palette($app-accent)};
  --color-accent-dark: #{mat.get-color-from-palette($app-accent, 500)};
  --color-warn: #{mat.get-color-from-palette($app-warn)};
  --color-success: #{mat.get-color-from-palette($app-success)};
  --color-error: #{mat.get-color-from-palette($app-error)};
  --color-bg-light: #{mat.get-color-from-palette($app-bg-light)};
  --card-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  --tippy-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  --card-border: 1px solid #f1f1f1;
  --font-bamboo: 'Bamboo';
  --font-quicksand: 'Quicksand';
  --bamboo-header-theme: #cb4242;
  --bamboo-table-header-theme: var(--graphite);
  --bamboo-row-highlighter: #cb424252;
  --color-white: #fff !important;
  --row-header: var(--graphite);
  --color-black: #000;
  --color-grey: #ccc !important;
  --color-light-grey: #ccccccd1;
  --color-table-row-odd: #e0e0e0;
}

/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-quicksand) !important;
}

.tox .tox-edit-area__iframe {
  background-color: var(--transparent) !important;
}

.mdc-linear-progress__buffer {
  background-color: var(--ash-20) !important;
}

.mind-elixir .context-menu .menu-list li {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.map-container me-parent me-tpc {
  max-width: 350px !important;
}

.mat-mdc-tab-header {
  border: 1px solid var(--ash-50) !important;
  border-radius: 10px 10px 0 0;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

me-tpc.selected {
  display: flex; /* Enable flex layout */
}

me-tpc.selected #input-box {
  flex: 1; /* Allow the element to grow within the flex container */
  min-width: 100% !important; /* Set minimum width to 100% */
}

.admin-charts-tab-group {
  mat-tab-header {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
}

.titleEdit .mdc-text-field {
  background-color: var(--graphite) !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: var(--transparent) !important;
  background: unset !important;
  border-color: var(--soul) !important;
  border-bottom: 0px !important;
}

.tox .tox-toolbar-overlord {
  background-color: var(--transparent) !important;
}
.tox .tox-toolbar__group {
  border: 0px !important;
}
.tox .tox-tbtn {
  color: #444 !important;
}
.tox .tox-tbtn svg {
  fill: #444 !important;
}

.tox-tinymce {
  border-radius: 10px !important;
}

.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  fill: rgba(34, 47, 62, 0.5) !important;
}

math-field {
  min-height: 100px;
  padding: 5px;
}

.mat-mdc-icon-button {
  color: var(--ash) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.highlighted-date {
  background: orange !important;
  border-radius: 100%;
}
// .mat-mdc-dialog-surface {
//   padding: 1rem;
// }
.input-form {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    border: 0 !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-mdc-card-image {
  display: inline-block !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.save-button > .mat-button-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

app-render-math {
  display: inline-block;
  align-items: center;
}

app-render-math p {
  margin: 0;
}

.mat-drawer-container {
  height: 100%;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 1rem;
}

.mdc-evolution-chip__text-label {
  color: var(--ash) !important;
}

// .rendered-math:not(.hidden):not(.hidden-details) {
//   display: inline-flex !important;
//   align-items: center;
// }
.rendered-math:not(.hidden):not(.hidden-details) > span {
  align-items: center;
}
.rendered-math:not(.hidden):not(.hidden-details) p > span {
  margin: 0 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-disabled {
  color: #ccc !important;
}

.mat-mdc-menu-panel.notification-menu {
  min-width: 320px !important;
  max-width: 450px !important;
}

.mat-badge-content {
  top: 0 !important;
  right: 0 !important;
}

.mdc-list-item__content {
  overflow: unset !important;
  visibility: visible;
  width: 100%;
}

.collapsed .mdc-list-item__content {
  white-space: nowrap;
  visibility: hidden !important;
}

.collapsed {
  .admin-user-div {
    .admin-userButton {
      display: none !important;
    }
  }
  .navbar-wrapper {
    .nav-item {
      .nav-item-text {
        display: none !important;
      }
    }
  }
}

.correct-answer > span {
  color: white;
  font-weight: 600;
  display: inline-block !important;
  padding: 0.25rem !important;
  background-color: #009600 !important;
  border-radius: 5px !important;
}

.correct-answer app-render-math {
  color: #fff !important;
  font-weight: 600;
  display: inline-block !important;
  padding: 0.25rem !important;
  background-color: #009600 !important;
  border-radius: 5px !important;
}

.med-dropdown {
  width: 300px;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.selected-list-wrapper .mat-chip-list-wrapper {
  justify-content: center;
}

.longer-dropdown {
  height: 100%;
  max-height: 50vh !important;
}

.mat-mdc-paginator-range-label {
  margin: 0 5px !important;
}

$custom-typography: mat.define-typography-config(
  $font-family: 'Quicksand, sans-serif',
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();
// tippy styles
.tippy-box {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: var(--tippy-shadow);
  max-width: unset !important;
}

.tippy-box[data-theme~='dark'] {
  background-color: #424242;
  box-shadow: var(--tippy-shadow);
}

.tippy-arrow {
  color: var(--ash);
}

// .tippy-content {
//   background-color: white;
//   border-radius: 0.5rem;
// }

// mat tab styles
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-wrapper {
  height: 100%;
}

// mat tooltip
.mat-mdc-tooltip {
  font-size: 1rem;
}

.my-custom-tooltip {
  max-width: unset !important;
}

.timelines-matform-field .mat-form-field-infix {
  border: 0;
  display: flex !important;
  width: 225px !important;
  .mat-form-field-wrapper {
    padding-bottom: 0.5em !important;
  }
  .mat-form-field-infix {
    border: 0;
  }
}

.timelines-matselect {
  .mat-form-field-wrapper {
    padding-bottom: 0.55em !important;
  }
  .mat-form-field-underline {
    bottom: 0.55em !important;
  }
}

// This fixes the irregular width issue of tabs when sidenav is open
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label-content {
    font-weight: 600;
    color: var(--graphite);
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: var(--color-accent) !important;
      right: 0;
      bottom: 0;
    }
  }
}

.grouped-data-accordion .mat-expansion-panel-body {
  padding: 0;
}

// mat-ink-bar {
//   display: none !important;
// }

// mat card style customization
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card.mat-mdc-card {
  border-radius: 12px;
  padding: 1rem;
  border: var(--card-border);
  transition: 0.4s;
  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--card-shadow);
  }
  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid var(--bottle-blue);
  }
}

.darkBackdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.demo-snackbar {
  max-width: 75% !important;
  margin: auto !important;
  margin-bottom: 2rem !important;
  text-align: center;
}

.mat-mdc-tooltip {
  white-space: pre-line; // Allow line break
}

.app-top-bleed-dialog .mat-mdc-dialog-container {
  padding: 0 5px;
  overflow: hidden;
}

.mat-calendar-body-selected {
  background-color: #cb4242;
  color: #ffffff;
  border: none;
}

.mat-mdc-dialog-container {
  background-color: var(--soul);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-label-content {
  line-height: 32px !important;
}

.shepherd-modal-overlay-container {
  height: 100vh !important;
  opacity: 0.5 !important;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s !important;
}

//pdf viewer styles
.ng2-pdf-viewer-container {
  .page {
    position: relative;
  }
  &.center-page {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
  }
}

.invisible {
  visibility: hidden;
}

// snackbar styles
.mat-mdc-snack-bar-container {
  color: white !important;
  font-weight: 400;
  &.success {
    .mdc-snackbar__surface {
      background-color: var(--color-success);
    }
    background-color: var(--color-success) !important;
  }
  &.warning {
    .mdc-snackbar__surface {
      background-color: var(--color-warn);
    }
    background-color: var(--color-warn) !important;
  }
  &.error {
    .mdc-snackbar__surface {
      background-color: var(--color-error);
    }
    background-color: var(--color-error) !important;
  }
}

// custom scrollbar

/* Works on Firefox */

* {
  scrollbar-width: auto;
  scrollbar-color: var(--light-gray);
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--soul);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 16px;
}

::ng-deep .mat-form-field-placeholder {
  color: rgb(94, 91, 91) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label {
  color: rgb(94, 91, 91) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-outline,
.mat-form-field-empty.mat-form-field-label,
.mat-form-field-label,
.mat-form-field-underline,
.mat-input-element,
::placeholder {
  color: rgb(94, 91, 91) !important;
}

::ng-deep .mat-focused .mat-form-field-placeholder {
  color: rgb(94, 91, 91) !important;
}

*::placeholder {
  color: rgb(94, 91, 91) !important;
  opacity: 1 !important;
}

app-html-editor {
  width: 100%;
  margin-top: 10px;
}

.completed-question app-render-math * {
  text-decoration: line-through;
}

.answer-row-wrapper *.mat-expansion-panel-body {
  padding-bottom: 0;
}

.container {
  margin: 0 auto;
  width: 75%;
}

//  bottom sheet styles
.comment-list-panel {
  width: 100vw;
  max-width: 1000px !important;
  .mat-bottom-sheet-container {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.custom-prompt-bottomsheet {
  .mat-bottom-sheet-container {
    border-radius: 20px 20px 0 0;
    min-width: unset;
  }
}

img {
  max-width: 100%;
}
.darkBackdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

:root .shepherd-element {
  font-size: 1em;
  max-width: 750px !important;
  z-index: 9999;
}

:root .shepherd-text {
  max-height: 55vh !important;
  touch-action: auto;
  scroll-behavior: auto;
  overflow: auto;
}

:root .shepherd-modal-overlay-container {
  opacity: 0.8 !important;
}

:root .shepherd-button {
  padding-top: 5px;
  background-color: var(--color-accent) !important;
}

:root .shepherd-button.shepherd-button-secondary {
  padding-top: 5px;
}

:root .shepherd-has-title .shepherd-content .shepherd-header {
  padding: 0.5rem;
  background-color: var(--bottle-blue);
  color: white;
  .shepherd-title,
  .shepherd-cancel-icon {
    color: white;
  }
  .shepherd-cancel-icon {
    padding-right: 0.5rem;
  }
}

.mdc-linear-progress__bar {
  text-align: left;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.grayed-progress {
  .mdc-linear-progress__buffer {
    text-align: left;

    background-color: lightgray !important;
  }
}

.green-progress {
  .mdc-linear-progress__bar,
  .mdc-linear-progress__bar-inner {
    text-align: left;
    background-color: #009f00 !important;
    border-color: #009f00 !important;
  }
  .mdc-linear-progress__buffer {
    background-color: #cceecc !important;
  }
}

.warn-progress {
  .mdc-linear-progress__bar,
  .mdc-linear-progress__bar-inner {
    text-align: left;
    background-color: #ff9500 !important;
    border-color: #ff9500 !important;
  }
  .mdc-linear-progress__buffer {
    background-color: #eeddcc !important;
  }
}

.red-progress {
  .mdc-linear-progress__bar,
  .mdc-linear-progress__bar-inner {
    text-align: left;
    background-color: #cf1717 !important;
    border-color: #cf1717 !important;
  }
  .mdc-linear-progress__buffer {
    background-color: #ffc1c1 !important;
  }
}

:root .shepherd-element .shepherd-content {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17);
}
.maxWidth-panel {
  position: relative !important;
  width: 100%;
  .mat-mdc-dialog-container {
    overflow-y: auto;
    height: 100%;
    max-height: 98vh;
    border-radius: 10px;
  }
}

.paddingless-matform-field {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0.5em !important;
  }
}

.maxWidth-panel-paddingless {
  position: relative !important;
  padding: 0 !important;
  width: 100%;
  .mat-mdc-dialog-container {
    overflow-y: auto;
    height: 100%;
    max-height: 98vh;
  }
}

.transparent-background {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0.5rem 0;
}

.transparent-background .mat-expansion-panel-header {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  margin: 0.5rem 0;
  height: 100% !important;
}

.mat-mdc-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-mdc-unelevated-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-mdc-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mdc-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled {
  color: var(--ash);
  background-color: #ccc !important;
}

@media screen and (max-width: 600px) {
  .maxWidth-panel {
    width: 100%;
    max-width: 95vw !important;
  }
  .maxWidth-panel-paddingless {
    width: 100%;
    max-width: 95vw !important;
  }

  .tox .tox-dialog {
    height: 98% !important;
  }
  .tox .tox-dialog__footer {
    padding-bottom: 1rem;
  }
}

.mind-elixir .mobile-menutoolbar {
  position: absolute;
  left: 20px;
  bottom: 70px;
  z-index: 999;
  margin: 0;
  padding: 0;
  color: #333;
  border-radius: 5px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  * {
    transition: color 0.4s, background-color 0.4s;
  }
  div {
    float: left;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    padding: 10px;
    background-color: #2d3748 !important;
    a {
      color: #333;
      text-decoration: none;
    }
    i {
      color: #fff !important;
      font-size: 1.25rem;
      font-weight: lighter;
    }

    &.disabled {
      color: rgb(94, 94, 94);
      background-color: rgb(247, 247, 247);
      &:hover {
        cursor: default;
        background-color: rgb(247, 247, 247);
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #2d3748dc !important;
    }
  }
}

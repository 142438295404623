//global app styles
@import './global.scss';
// Tippy js styles
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/themes/translucent.css';
@import '~tippy.js/animations/scale.css';
// plyr styles
@import '~plyr/dist/plyr.css';
// Custom font
@import '@fontsource/quicksand/600.css';
@import '@fontsource/quicksand/400.css';
.paddingless-dialog-container .mat-mdc-dialog-container {
  /* add your styles */
  padding: 0px;
}
